
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { FetchReturnClientError, MeetingUpdate, RootState, ThunkDispatchType } from "../../../store";
import { selectLocations, selectParticipantsWithSelections } from "../../../store/schedule/selectors";
import confetti from "canvas-confetti";
import { trackEvent } from "../../../utils/appAnalyticsUtils";
import { EVENT_TYPE } from "../../../constants";
import { bookGroupMeeting, updateMeeting } from "../../../store/schedule/actions";
import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { BookMeetingModal } from "./BookMeetingModal";
import { sendMessage } from "../../../store/globalMessages/actions";
import { Clipboard } from '@capacitor/clipboard';

interface BookMeetingModalContainerProps {
  open: boolean;
  meetingId: number;
  start?: DateTime;
  end?: DateTime;
  onClose: () => void;
}

const BookMeetingModalContainer = ({
  open, meetingId, start, end, onClose
}: BookMeetingModalContainerProps) => {
  const [isBooking, setIsBooking] = useState(false);
  const [bookingConfirmationMessage, setBookingConfirmationMessage] = useState<null | string>(null);
  const [bookingError, setBookingError] = useState<FetchReturnClientError<string> | null>(null);

  const dispatch = useDispatch<ThunkDispatchType>();
  
  const meeting = useSelector((state: RootState) => state.schedule.meetings[meetingId]);
  const locations = useSelector((state: RootState) => selectLocations(state, meetingId));
  const participants = useSelector((state: RootState) => selectParticipantsWithSelections(state, meetingId));
  const features = useSelector((state: RootState) => state.auth.user?.features);
  const leaders = useSelector((state: RootState) => state.leaders.leaders);

  const meetingLeaders = meeting.leaders.map(meetingLeaderId => {
    const leader = leaders.find(l => l.id === meetingLeaderId);
    return {key: leader?.id, value: leader?.email};
  }).filter((l): l is {key: number, value: string} => !!l.key && !!l.value);
   
  const meetingAttendees = participants.map(p => ({ key: p.id ?? 0, value: p.email ?? '' }));

  const attendeeEmails = [...meetingLeaders, ...meetingAttendees];

  const confirmBookMeeting = async () => {
    setIsBooking(true);
    try {
      if (!meeting || !start || !end) {
        throw Error('No meeting slot selected');
      }

      const res = await dispatch(bookGroupMeeting(
        meetingId,
        start.toISO() || "",
        meeting?.create_user?.id,
      ));
      if (res?.status === 400) {
        setBookingError(res);
      }

      if (res?.status === 200) {
        if (participants.length !== 1) {
          setBookingConfirmationMessage(`Invitations have been sent to ${participants.length} respondents`);
        } else {
          setBookingConfirmationMessage(`Invitation has been sent to ${participants.length} respondent`);
        }
        confetti();
        trackEvent(meeting.is_poll ? EVENT_TYPE.MEETING_POLL_BOOKED : EVENT_TYPE.MEETING_BOOKED);
      } else {
        setBookingConfirmationMessage(
          'Uh oh, something went wrong. Please contact help@joincabinet.com for assistance.');
      }
      
    } catch (err) {
      setBookingConfirmationMessage('There was a problem creating your calendar event.');
    } finally {
      setIsBooking(false);
      onClose();
    }
  };

  const handleSaveMeeting = async (title: string, description: string) => {
    if (meeting) {
      return handleMeetingUpdate({
        id: meeting.id,
        title: title,
        description: description
      });
    }
  };


  const handleMeetingUpdate = useCallback(async (meetingUpdate: MeetingUpdate) => {
    await dispatch(updateMeeting(meetingUpdate, []));
  }, [dispatch]);


  const handleCopyToClipboard = async (textToCopy: string | undefined) => {
    if (!textToCopy) {
      return;
    }
    await Clipboard.write({ string: textToCopy });
    dispatch(sendMessage({
      timeout: 750,
      message: 'Copied',
      header: "",
      position: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      active: true,
      severity: 'success',
      autoDismiss: true
    }));
  };


  return <>
    <BookMeetingModal
      open={open}
      isBooking={isBooking}
      meeting={meeting}
      start={start}
      end={end}
      attendeeEmails={attendeeEmails}
      locations={locations} 
      allowEditAttendees={!!features?.PREREGISTERED_ATTENDEES}
      bookingError={bookingError}
      bookingConfirmationMessage={bookingConfirmationMessage}
      onSaveMeeting={handleSaveMeeting}
      onClearBookingConfirmationMessage={() => setBookingConfirmationMessage(null)}
      onClose={onClose}
      onClearBookingError={() => setBookingError(null)}
      onCopyToClipboard={handleCopyToClipboard}
      confirmBookMeeting={confirmBookMeeting}
    />
  </>;
};

export default BookMeetingModalContainer;