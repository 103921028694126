import { ReactElement, useEffect, useState } from "react";
import EditMeetingLocationsModal from "./EditLocationsModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ThunkDispatchType, RootState, Meeting, Calendar, actions } from "../../../store";
import { PAGE_URL } from "../../../constants";


interface EditLocationsContainerProps {
  onModalClose: () => void;
  modalOpen: boolean;
  meetingId: number;
}

const EditLocationsModalContainer = ({onModalClose, modalOpen, meetingId}: 
EditLocationsContainerProps): ReactElement | null => {

  const [leaderCalendarOptions, setLeaderCalendarOptions] = useState<Calendar[]>([]);

  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const leaders = useSelector((state: RootState) => state.leaders);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);
  const meetings = useSelector((state: RootState) => state.schedule.meetings);
  const zoomSettings = useSelector((state: RootState) => state.schedule.zoomSettings);
  const meetingRooms = useSelector((state: RootState) => state.schedule.meetingRooms);

  const presetLocations = useSelector((state: RootState) => state.schedule.presetLocations);

  const currentMeeting = meetings[meetingId] as Meeting | undefined;
  const isOwner = user?.id === currentMeeting?.create_user?.id;

  useEffect(() => {
    setLeaderCalendarOptions(
      calendars.filter(cal => cal.canEdit && currentMeeting?.leaders.some(l => cal.leaders.includes(l)))
    );
  }, [calendars, currentMeeting?.leaders]);

  if (!currentMeeting) {
    return null;
  }
  const handleUpdateMeeting = async (meetingPartial: Partial<Meeting>) => {
    await dispatch(actions.schedule.updateMeeting({ ...meetingPartial, id: currentMeeting.id }, []));
  };

  const handleAddZoom = () => {
    onModalClose();
    navigate(PAGE_URL.INTEGRATION_SETTINGS);
  };

  return (
    <EditMeetingLocationsModal
      meeting={currentMeeting}
      zoomSettings={zoomSettings}
      user={user}
      leaders={leaders}
      leaderCalendarOptions={leaderCalendarOptions}
      onChangeValues={handleUpdateMeeting}
      onAddZoom={handleAddZoom}
      isOwner={isOwner}
      calendars={calendars}
      meetingRooms={meetingRooms}
      presetLocations={presetLocations}
      onModalClose={onModalClose}
      modalOpen={modalOpen}
    />
  );
};

export default EditLocationsModalContainer;