import { CabButton } from "@CabComponents/CabButton";
import { CabModal } from "@CabComponents/CabModal";
import { Typography } from "@mui/material";
import { FetchReturnClientError } from "../../../store";



type BookMeetingErrorModalProps = {
  bookingError: FetchReturnClientError<string> | null
  clearError: () => void
};

export const BookMeetingErrorModal = ({bookingError, clearError}: BookMeetingErrorModalProps) => {
  let suggestionText = "For help with this, you can email support@joincabinet.com.";
  switch (bookingError?.data.code) {
    case "token_does_not_exist":
      suggestionText = "Please check that your Google or Microsoft account is connected to Cabinet. " + suggestionText;
  }
  
  
  return <CabModal
    open={!!bookingError}
    closeIcon={true}
    onClose={clearError} title="Unable to book Poll"
    actionButtons={<>
      <CabButton type="button" onClick={clearError}>Ok</CabButton>
    </>}
  >
    <Typography>There was an issue booking this meeting. You can still book it on your calendar directly.</Typography>
    {suggestionText &&
      <Typography sx={{marginTop: 2}}>{suggestionText}</Typography>
    }
  </CabModal>;
};

export default BookMeetingErrorModal;