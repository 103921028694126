import CabCard from "@CabComponents/CabCard";
import { styled, Typography } from "@mui/material";
import { useMemo } from "react";
import { MeetingQuestion, MeetingQuestionAnswer, SelectedSlots } from "../../../store";
import { QuestionType } from "../../../store/schedule/types";
import { getAnswerString } from "../../../utils/scheduleUtils";


export type PollQuestionResultsProps  = {
  questions?: {[id: number]: MeetingQuestion}
  questionAnswers?: {[id: number]: MeetingQuestionAnswer}
  participants?: { 
    id?: number;
    emailHash: string;
    name: string;
    email: string | null;
    selectedSlots: SelectedSlots;
  }[];
};

const QuestionContainer = styled("div", {label: "QuestionContatiner"})({
  display: "flex",
  margin: "16px 0",
  alignItems: "bottom"
});

const PollQuestionResults = ({questions, questionAnswers, participants}: PollQuestionResultsProps) => {
  const questionToAnswersMap = useMemo(() => {
    const mapping: {[id: number]: MeetingQuestionAnswer[]} = {};
    Object.values(questionAnswers || {})?.forEach(answer => {
      console.log(answer);
      if ((questions && questions[answer.question]?.question_type === QuestionType.TEXT) && answer.text?.length) {
        if (mapping[answer.question]) {
          mapping[answer.question].push(answer);
        } else {
          mapping[answer.question] = [answer];
        }
      }
      if ((questions && questions[answer.question]?.question_type !== QuestionType.TEXT) && answer.options 
      && answer.options[0] && questions[answer.question].options) {
        if (mapping[answer.question]) {
          mapping[answer.question].push(answer);
        } else {
          mapping[answer.question] = [answer];
        }
      }
    });
    return mapping;
  }, [questionAnswers, questions]);

  return (
    <div>
      {questions && Object.values(questions)?.map((question, idx) => {
        return <div key={question.id}>
          <QuestionContainer>
            <Typography variant="subtitle2">{idx + 1}. {question.title}
              <Typography>
                Responses ({questionToAnswersMap[question.id]?.length  || 0})
              </Typography>
            </Typography>
            
          </QuestionContainer>
          
          {questionToAnswersMap[question.id]?.map((answer => {
            const participant = participants?.find(p => Number(p.id) === answer.participant);
            return <CabCard
              key={answer.id}
              headerContent={getAnswerString(answer, question)}
              sx={{marginBottom: 2}}        
            >
              {participant?.name || participant?.email || ""}
            </CabCard>;
          }))}
        </div>;
      })}
    </div>
  );
};


export default PollQuestionResults;
